<template>
    <LoadingDialog :visible="actionIsBeingExecuted" />

    <ConfirmationModal 
      :title="currentConfirmationData.title"
      :text="currentConfirmationData.text"
      :visible="confirmationModalVisibility"
      @emitResponse="confirmationResponse"
    />
    <LoaderComponent class="col-span-5 text-center pt-5 mt-5" v-if="tabLoading" />
    <div class="content-wrapper col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5" v-if="!tabLoading">
        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-6 text-lg font-semibold">{{ translate('visas', 'ucfirst') }}</h1>
            <form
                class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-6 text-base font-medium"
            >
                <span class="col-span-3 p-float-label">
                    <AutoComplete
                        v-model="visasName" 
                        :suggestions="visasList" 
                        optionLabel="name"
                        @complete="searchForVisas"
                        :placeholder="`${translate('type to search')}...`"
                        :emptyMessage="translate('no results found', 'ucfirst')"
                        :emptySearchMessage="translate('no results found', 'ucfirst')"
                        :inputClass="'w-full'"
                        multiple
                        fluid 
                        dropdown
                        class="w-full rounded border border-iron autocomplete-w-full"
                    />
                </span>
                <hr class="iron pt-2 pb-2 col-span-3">
                <div class="col-span-3 flex flex-wrap">
                    <div v-for="(visa, index) of visasName" :class="`w-full mb-3 md:pe-3 md:w-1/3 visa-opt-${visa.code}-${visa.name.replace(' ', 'aaaa-aaaa-aaaa')}`" :key="index">
                        <span>{{ visa.name }}</span>: 
                        <Dropdown
                            v-model="countriesCombo.selected[`${visa.code}-${visa.name.replace(' ', 'aaaa-aaaa-aaaa')}`]"
                            :options="countriesCombo.data"
                            @change="highlightDropDown(`${visa.code}-${visa.name.replace(' ', 'aaaa-aaaa-aaaa')}`, false);"
                            optionLabel="name"
                            :placeholder="translate('select a country', 'ucfirst')"
                            class="w-full rounded-full border border-iron dropdown-el"
                            required
                            filter
                            :id="`country-${visa.code}-${visa.name.replace(' ', 'aaaa-aaaa-aaaa')}`"
                        />
                    </div>
                </div>
            </form>
        </section>
    </div>
    <ActionCard 
        v-if="!tabLoading"
        :jobId="jobId"
        :jobStatus="props.jobStatus"
        :actionButtonText="translate('save visas', 'ucfirst')"
        @emitClick="openConfirmationModal('visaSave')"
        class="col-span-3 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
    />
</template>

<script setup>
    import { ref, onMounted, watch, reactive, onBeforeMount } from "vue";
    import requestHandler from "@/composables/requestHandler";
    import AutoComplete from "primevue/autocomplete";
    import { translate } from "@/composables/translate";
    import { sleep } from "@/composables/sleep";
    import store from "@/store";
    import ActionCard from "@/components/Dashboard/Company/SaveJob/ActionCard"
    import ConfirmationModal from "@/components/Utils/ConfirmationModal";
    import Dropdown from "primevue/dropdown";
    import jobData from "@/composables/jobData";
    import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
    import { showToast } from "@/composables/toast";
    import { useToast } from "primevue/usetoast";
    import LoaderComponent from "@/components/Utils/LoaderComponent.vue";

    let toast = useToast();

    const props = defineProps({
        jobId: {
            type: String,
            required: false,
            default: null
        },
        jobStatus: {
            type: String,
            required: false,
            default: 'draft'
        }
    });

    let tabLoading = ref(true);
    let actionIsBeingExecuted = ref(false);
    let jobId = ref(null);
    onMounted(function(){
        handleAutoCompleteElements();
        getEssentialData();
    });

    onBeforeMount(function(){
        jobId.value = props.jobId;
        if(jobId.value == null)
            goToJobPage();
    });

    async function getEssentialData(){
        tabLoading.value = true;
        await feedCountriesCombo();
        await feedVisasCombo();
        await fetchAndFeedVisas();
        tabLoading.value = false;
    }

    async function fetchAndFeedVisas(){
        if(!jobId.value)
            return;
        let values = await jobData.searchTypeVisas(jobId.value);
        visasName.value = values.visas;
        for(let key in values.country){
            countriesCombo.selected[key] = values.country[key];
        }
    }

    let countriesCombo = reactive({
        data: [],
        selected: [],
        loading: false
    })
    async function feedCountriesCombo(){
        countriesCombo.loading = true;
        const result = await jobData.getJobComboData('job_country');
        countriesCombo.data = result;
        countriesCombo.loading = false;
    }

    let visasCombo = reactive({
        data: [],
        selected: [],
        loading: []
    })
    async function feedVisasCombo(){
        visasCombo.loading = true;
        const result = await jobData.getJobComboData('type_visas');
        visasCombo.data = result;
        visasCombo.loading = false;
    }

    async function goToJobPage(){
        await requestHandler.redirect(`/dashboard/company/${store.getters.getUserSlug}/post-job`);
    }

    watch(() => props.jobId, (newValue) => {
      jobId.value = newValue;
      handleAutoCompleteElements();
    });

    
    let confirmationModalVisibility = ref(false);
    const confirmationData = {
        visaSave: {
            title: translate('are you sure?', 'ucfirst'),
            text: translate('do you wish to save these visas? This action will make your job unavaliable until it passes by our validation trials', 'ucfirst')
        }
    }
    let currentConfirmationData = reactive({
        title: '',
        text: '',
        action: ''
    })
    function openConfirmationModal(action = ''){
        currentConfirmationData.action = '';
        confirmationModalVisibility.value = false;
        if(action == '' || !['visaSave'].includes(action))
            return;
        currentConfirmationData.action = action;
        currentConfirmationData.title = confirmationData[action].title;
        currentConfirmationData.text  = confirmationData[action].text;
        confirmationModalVisibility.value = true;
    }

    let visasName = ref([]);
    let visasList = ref([]);
    async function searchForVisas(event){
      sleep(500);
      visasList.value = [];
      let term = event.query.toLowerCase();
      let matches = [];
      for(let visa of visasCombo.data){
        if(matches.length >= 5)
            break;
        let undercase = visa.name.toLowerCase();
        if(undercase.indexOf(term) != -1){
            matches.push(visa);
        }
      }
      visasList.value = matches;
    }

    async function handleAutoCompleteElements(){
      let tries = 0;
      let autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
      while(autocompleteEls.length < 1){
          autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
          tries++;
          await sleep(50);
          if(tries == 30){
            break;
          }
      }
      if(autocompleteEls.length < 1)
          return;
      autocompleteEls.forEach(el => {
        if(el.style.width == '')
          el.style.setProperty('width', '100%', 'important');
          const ulEl = el.querySelector('ul');
          if(ulEl){
            ulEl.style.setProperty('width', '100%', 'important');
            // ulEl.style.setProperty('border-radius', '9999px', 'important');
            let inputEl = ulEl.querySelector('input');
          if(inputEl){
            inputEl.style.setProperty('width', '100%', 'important');
            inputEl.style.setProperty('outline', 'unset', 'important');
          }
        }
      });
    }

    async function confirmationResponse(result){
        confirmationModalVisibility.value = false;
        if(result.confirmation){
            actionIsBeingExecuted.value = true;
            switch(currentConfirmationData.action){
                case 'visaSave':
                    await saveVisas();
                break;
            }
            actionIsBeingExecuted.value = false;
        }
        currentConfirmationData.action = '';
    }

    async function saveVisas(){
        let visasIds   = [];
        let visasNames = [];
        let countriesIds = [];
        for(let data of visasName.value){
            visasIds.push(data['code']);
            visasNames.push(data['name']);
            let id = `${data.code}-${data.name.replace(' ', 'aaaa-aaaa-aaaa')}`;
            if(countriesCombo.selected[id] == undefined){
                showToast(toast, translate('no country informed'), 'error');
                highlightDropDown(id);
                return;
            }
            highlightDropDown(id, false);
            countriesIds.push(countriesCombo.selected[id].code);
        }
        const response = await requestHandler.executeRequest(`api/joblist/managevisas/${jobId.value}`, {
            action: 'sync', job_visas_ids: visasIds, visa_countries_ids: countriesIds
        }, 'post');
        if(!response.success){
            let errorMessage = visasIds.length > 1 ? translate('visas not saved') : translate('visa not saved');
            showToast(toast, errorMessage, 'error');
            return;
        }
        let successMessage = visasIds.length > 1 ? translate('visas saved') : translate('visa saved');
        showToast(toast, successMessage);
        await jobData.updateMyCompanyJobs(jobId.value, response.data.data);
        store.dispatch('saveOnDataChest', {
            key: `save_job_${jobId.value}`,
            value: response.data.data
        });
    }

    function highlightDropDown(id = '', mark = true){
        id = id.replace(' ', 'aaaa-aaaa-aaaa');
        let el = document.querySelector(`.visa-opt-${id}`);
        if(el != undefined && el.querySelector('.dropdown-el') != null){
            el.querySelector('.dropdown-el').style.border = mark ? '1px solid red' : '';
        }
    }
</script>