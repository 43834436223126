import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const countries = {
    async listCountries() {
        try {
            let countryCombo = store.getters.getOnComboChest('country');
            if(countryCombo !== null && countryCombo !== undefined && !utils.isEmpty(countryCombo))
                return countryCombo;
            let countriesFileData = requestHandler.fetchOnPublicData('countries.json');
            if(countriesFileData){
                store.dispatch('saveOnComboChest', {
                    key: 'common_currency',
                    value: countriesFileData
                });
                return countriesFileData
            }
            const result = await requestHandler.executeRequest('api/countries/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'country',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default countries;