<template>
  <aside class="z-30">
    <!-- Menu mobile Start -->
    <div
      class="flex lg:hidden flex-col items-center w-16 h-full overflow-hidden text-gray-700 bg-white rounded"
    >
      <RouterLink class="flex items-center justify-center mt-3" to="/">
        <img
          src="../../../../public/img/logo-icon-jobifull.png"
          alt="Icon Logotipo jobifull"
          class="w-12"
        />
      </RouterLink>
      <div class="flex flex-col items-center mt-3 border-t border-gray-300">
        <RouterLink
          :to="`/dashboard/${userType}/${username}`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-home" style="font-size: 1.3rem"></i>
        </RouterLink>
        <RouterLink
          :to="`/jobs`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-briefcase" style="font-size: 1.3rem"></i>
        </RouterLink>
        <RouterLink
          v-if="[undefined, null, 0].includes(env('APP_BETA'))"
          :to="`/dashboard/${userType}/${username}/jobs`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-briefcase" style="font-size: 1.3rem"></i>
        </RouterLink>
        <RouterLink
          v-if="[undefined, null, 0].includes(env('APP_BETA'))"
          :to="`/dashboard/${userType}/${username}/create-curriculo`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-file-edit" style="font-size: 1.3rem"></i>
        </RouterLink>
        <RouterLink
          v-if="[undefined, null, 0].includes(env('APP_BETA'))"
          :to="`/dashboard/${userType}/${username}/messages`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-comment" style="font-size: 1.3rem"></i>
        </RouterLink>
      </div>
      <div class="flex flex-col items-center mt-2 border-t border-gray-300">
        <RouterLink
          v-if="[undefined, null, 0].includes(env('APP_BETA'))"
          :to="`/dashboard/${userType}/${username}/`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-history" style="font-size: 1.3rem"></i>
        </RouterLink>
        <RouterLink
          v-if="[undefined, null, 0].includes(env('APP_BETA'))"
          :to="`/dashboard/${userType}/${username}/settings`"
          class="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
        >
          <i class="pi pi-sliders-v" style="font-size: 1.3rem"></i>
        </RouterLink>
      </div>
      <RouterLink
        v-if="[undefined, null, 0].includes(env('APP_BETA'))"
        :to="`/dashboard/${userType}/${username}/profile`"
        class="flex items-center justify-center w-16 h-16 mt-auto bg-sea-serpent hover:bg-oxford-blue"
      >
        <svg
          class="w-6 h-6 stroke-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </RouterLink>
    </div>
    <!-- Menu mobile End  -->

    <!-- Menu web Start -->
    <div
      class="sticky top-0 h-screen hidden lg:flex flex-col items-center md:w-60 overflow-hidden overflow-y-auto bg-white"
    >
      <RouterLink class="flex justify-center w-full px-3 mt-6 mb-2" to="/">
        <img
          src="../../../../public/img/jobifull-retangular.png"
          alt="Logotipo jobifull"
          class="w-28"
        />
      </RouterLink>
      <div class="w-full px-4 mb-5">
        <div class="flex flex-col items-center w-full mt-3 border-t border-gray-300">
          <RouterLink
            class="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-oxford-blue hover:text-white"
            :to="`/dashboard/${userType}/${username}`"
          >
            <i class="pi pi-home" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">{{ translate('dashboard', 'ucfirst') }}</span>
          </RouterLink>
          <RouterLink
            :to="`/jobs`"
            class="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-oxford-blue hover:text-white"
          >
            <i class="pi pi-briefcase" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">{{ translate('search for jobs', 'ucfirst') }}</span>
          </RouterLink>
          <RouterLink
            v-if="[undefined, null, 0].includes(env('APP_BETA'))"
            class="flex items-center w-full h-12 px-3 mt-2 hover:bg-oxford-blue hover:text-white rounded"
            :to="`/dashboard/${userType}/${username}/jobs-application`"
          >
            <i class="pi pi-briefcase" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">Candidatuas</span>
          </RouterLink>
          <RouterLink
            v-if="[undefined, null, 0].includes(env('APP_BETA'))"
            class="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-oxford-blue hover:text-white"
            :to="`/dashboard/${userType}/${username}/create-curriculo`"
          >
            <i class="pi pi-file-edit" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">Criar curriculo</span>
          </RouterLink>
        </div>
        <div class="flex flex-col items-center w-full mt-2 border-t border-gray-300" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
          <RouterLink
            class="relative flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-oxford-blue hover:text-white"
            :to="`/dashboard/${userType}/${username}/messages`"
          >
            <i class="pi pi-comment" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">Mensagens</span>
            <span
              class="absolute top-0 left-0 w-2 h-2 mt-2 ml-2 bg-indigo-500 rounded-full"
            ></span>
          </RouterLink>
          <RouterLink
            class="hidden 6flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-oxford-blue hover:text-white"
            :to="`/dashboard/${userType}/${username}/relators`"
          >
            <i class="pi pi-history" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">Relatórios</span>
          </RouterLink>
          <RouterLink
            class="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-oxford-blue hover:text-white"
            :to="`/dashboard/${userType}/${username}/settings`"
          >
            <i class="pi pi-sliders-v" style="font-size: 1.3rem"></i>
            <span class="ml-2 text-sm font-medium">Configurações</span>
          </RouterLink>
        </div>
      </div>
      <RouterLink 
        v-if="[undefined, null, 0].includes(env('APP_BETA'))"
        class="flex items-center justify-center text-white w-full h-16 py-4 mt-auto bg-sea-serpent hover:bg-oxford-blue link-profile"
        :to="`/dashboard/${userType}/${username}/profile`"
      >
        <i class="pi pi-user" style="font-size: 1.3rem"></i>
        <span class="ml-2 text-sm font-medium">Conta</span>
      </RouterLink>
    </div>
    <!-- Menu web End  -->
  </aside>
</template>

<script setup>
import { computed } from "vue";
import { RouterLink } from "vue-router";
import { useStore } from "vuex";
import { env } from "@/composables/config";
import { translate } from "@/composables/translate";

const store = useStore();
let username = computed(() => store.getters.getUserName);
let userType = computed(() => store.getters.getLoggedAs);

</script>
<style scoped>
.link-profile.router-link-exact-active {
  color: #fff;
}
</style>
