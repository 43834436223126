<template>
  <Card
    v-for="(applicant, index) in props.data"
    :key="index"
    class="shadow-md border border-iron"
  >
    <template #content>
      <div class="flex flex-col items-center text-center pb-3">
        <!-- Foto de perfil -->
        <div
          class="w-24 h-24 rounded-full mb-3 bg-slate-200 border border-iron profile-photo"
        ></div>

        <h1 class="text-lg font-bold text-oxford-blue">{{ applicant.name }}</h1>
        <p class="text-sm font-semibold mb-1">{{ applicant.hability }}</p>
        <span class="flex items-center gap-x-2">
          <i class="pi pi-map-marker text-sm"></i>
          <p class="text-[0.85rem] font-medium">{{ applicant.address }}</p>
        </span>

        <Rating
          v-model="applicant.ration"
          class="mx-auto mt-2.5"
          readonly
          :cancel="false"
        />

        <div
          class="w-full flex justify-between items-center mt-4 -mx-7 -mb-6 pt-[0.500rem] text-sm font-semibold text-oxford-blue border-t border-iron"
        >
          <RouterLink :to="`/applicants/${applicant.slug}`">Ver Perfil</RouterLink>
          <RouterLink :to="`/dashboard/${username}/jobs/${applicant.slug}`"
            >Mensagem</RouterLink
          >
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup>
import { computed } from "vue";
import { RouterLink } from "vue-router";
import { useStore } from "vuex";
import Card from "primevue/card";
import Rating from "primevue/rating";

const store = useStore();
let username = computed(() => store.state.username);

let props = defineProps(["data"]);
</script>

<style scoped></style>
