<script setup>
import { ref, onMounted } from "vue";
import { RouterView } from "vue-router";
import Header from "@/components/HeaderComponent.vue";
import Footer from "@/components/FooterComponent.vue";
import CookiePopup from "@/components/CookiePopupComponent.vue";
import cookiesHandler from "@/composables/cookiesHandler";

const visiblity_popup = ref(false);
function setCookieAcceptance(event) {
  cookiesHandler.setEncryptedCookie('cookies-acceptance', event);
  // document.cookie = "cookies-acceptance" + "=" + event;
  visiblity_popup.value = false;
}
const getCookieAcceptance = () => {
  // let value = document.cookie.match("(^|;)?" + "cookies-acceptance" + "=([^;]*)(;|$)");
  // return value ? unescape(value[2]) : null;
  return cookiesHandler.hasCookie('cookies-acceptance') ? cookiesHandler.getEncryptedCookie('cookies-acceptance') : null;
};
onMounted(() => {
  setTimeout(() => {
    if(getCookieAcceptance() != null){
      visiblity_popup.value = false;
    }else{
      visiblity_popup.value = true;
    }
    // getCookieAcceptance() == "true"
    //   ? (visiblity_popup.value = false)
    //   : (visiblity_popup.value = true);
  }, 5000);
});
</script>

<template>
  <div>
    <Header />

    <RouterView> </RouterView>

    <CookiePopup
      @accept-cookie="setCookieAcceptance($event)"
      class="transition-all duration-[5000ms] ease-in-out"
      :class="visiblity_popup ? 'flex d-translate-x-[26rem]' : 'translate-y-56 hidden'"
    />

    <Footer />
  </div>
</template>
