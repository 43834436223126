<template>
  <ProfileChooseComponent
    v-if="profileChoose.show"
    :action="'switchAccount'"
  />
  <LoadingDialog :visible="loading" />
  <div>
    <nav
      class="webmenu-navbar fixed top-0 left-0 right-0 bottom-[calc(100vh-theme(spacing.16))] z-50 hidden md:flex justify-between items-center font-semibold bg-white py-5 px-4 lg:px-8 xl:px-12 text-oxford-blue"
    >
      <div class="">
        <img
          src="../../public/img/jobifull-retangular.png"
          alt="Logotipo jobifull"
          class="w-20 lg:w-32"
        />
      </div>

      <ul
        class="flex flex-col md:flex-row space-y-1 md:space-y-0 md:space-x-6 lg:space-x-7"
      >
        <li><RouterLink to="/">{{ translate('home', 'ucfirst') }}</RouterLink></li>
        <li><RouterLink to="/jobs">{{ translate('jobs', 'ucfirst') }}</RouterLink></li>
        <li v-if="[undefined, null, 0].includes(env('APP_BETA'))"><RouterLink to="/applicants">{{ translate('professionals', 'ucfirst') }}</RouterLink></li>
        <li v-if="[undefined, null, 0].includes(env('APP_BETA'))"><RouterLink to="/companies">{{ translate('companies', 'ucfirst') }}</RouterLink></li>
        <li v-if="[undefined, null, 0].includes(env('APP_BETA'))"><RouterLink to="/plans">{{ translate('plans', 'ucfirst') }}</RouterLink></li>
      </ul>

      <div v-if="store.getters.isLoggedIn" class="flex items-center md:space-x-2">
        <i v-if="[undefined, null, 0].includes(env('APP_BETA'))" class="pi pi-bell mr-2" style="font-size: 1.5rem; color: #3c496c"></i>
        <Avatar 
          :image="store.getters.getProfileImage ? store.getters.getProfileImage : require('@/assets/images/avatar.png')"
          class="rounded-full w-10 h-10"
          shape="circle" 
        />
        <p>{{ store.getters.getNameByProfile }}</p>
        <Button
          type="button"
          :icon="`pi ${!toggleChevron ? 'pi-chevron-down' : 'pi-chevron-up'}`"
          @click="toggle"
          aria-haspopup="true"
          aria-controls="home_user_submenu"
        />
        <Menu ref="menu" id="home_user_submenu" :model="items" :popup="true">
          <template #item="{ item, props }">
            <div 
              v-if="item.profile.includes(store.getters.getLoggedAs) && (item.display == undefined || item.display == true)"
            >
              <RouterLink
                v-if="item.route"
                v-slot="{ href, navigate }"
                :to="item.route"
                custom
              >
                <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                  <span :class="item.icon" />
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </RouterLink>
              <a
                v-else
                v-ripple
                :href="item.url"
                :target="item.target"
                v-bind="props.action"
                @click.prevent="callMethod(item.action)"
              >
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </div>
          </template>
        </Menu>
      </div>

      <div v-else class="flex items-center md:space-x-2">
        <RouterLink to="/login" class="mr-3">{{ translate('enter', 'ucfirst') }}</RouterLink>
        <RouterLink to="/register" class="btn-register">{{ translate('register', 'ucfirst') }}</RouterLink>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { RouterLink } from "vue-router";
import Avatar from "primevue/avatar";
import store from "@/store";
import Menu from "primevue/menu";
import Button from "primevue/button";
import authUser from "@/composables/authUser";
import { showToast } from "@/composables/toast";
import { useToast } from 'primevue/usetoast';
import requestHandler from "@/composables/requestHandler";
import LoadingDialog from "./Utils/LoadingDialog.vue";
import { translate } from "@/composables/translate";
import ProfileChooseComponent from "./ProfileChooseComponent.vue";
import { env } from "@/composables/config";

const toast = useToast();
const profileChoose = reactive({
  show: false,
  profileName: null
});

// const props = defineProps(["user"]);
const loading = ref(false);
const toggleChevron = ref(false);
const menu = ref();
const items = ref([
  {
    label: "",
    items: [
      {
        label: translate('dashboard', 'ucfirst'),
        icon: "pi pi-home",
        route: `/dashboard/${store.getters.getLoggedAs !== null ? `${store.getters.getLoggedAs}/${store.getters.getUserSlug}` : 'profile'}`,
        profile: ['company','recruiter','professional',null]
      },
      {
        label: translate('publish job offer', 'ucfirst'),
        icon: "pi pi-file-edit",
        route: `/dashboard/${store.getters.getLoggedAs === null || store.getters.getLoggedAs !== 'company' ? 'profile' : `company/${store.getters.getUserSlug}/post-job`}`,
        profile: ['company']
      },
      {
        label: translate('switch account', 'ucfirst'),
        icon: "pi pi-user-edit",
        action: 'switchAccount',
        profile: ['company','recruiter','professional'],
        display: store.getters.getLoggedUserNotLoggedInProfile.length > 1 ? true : false 
      },
      {
        label: translate('end session'),
        icon: "pi pi-sign-out",
        name: "logout",
        action: 'logout',
        profile: ['company','recruiter','professional',null]
      },
    ],
  },
]);

const toggle = (event) => {
  toggleChevron.value = !toggleChevron.value;
  menu.value.toggle(event);
};

function callMethod(methodName = ''){
  switch(methodName){
    case 'logout':
      logout();
    break;
    case 'switchAccount':
      switchAccount();
    break;
  }
}

async function logout(){
  loading.value = true;
  const response = await authUser.logout();
  if(!response['success']){
    showToast(toast, response['message'], 'error');
  }else{
    store.dispatch('saveSessionMessage', {message: translate('logged out with success'), messageType: 'success'});
    requestHandler.redirect('/login');
  }
  loading.value = false;
}

async function switchAccount(){
  if(profileChoose.profileName == null){
    profileChoose.show = true;
    return;
  }
  profileChoose.show = false;
  showToast(toast, translate('profile changed', 'ucfrist'));
}
</script>

<style scoped></style>
