<template>
  <LoaderComponent v-if="isLoading" />
  <div v-if="!isLoading && jobs.length < 1">
    <h1 class="font-semibold">{{ translate('no jobs found', 'ucfirst') }}!</h1>
    <p>
      {{ translate('click here to create a new one', 'ucfirst') }}:
      <RouterLink :to="`/dashboard/company/${slug}/post-job`" class="underline font-semibold text-oxford-blue" >
        {{ translate('create job', 'ucfirst') }}
      </RouterLink>
    </p>
  </div>
  <section v-if="!isLoading && jobs.length > 0" class="grid grid-cols-1 md:grid-cols-4 gap-5">
    <div class="col-span-1 md:col-span-4 flex justify-between items-center mb-1" v-if="props.seeMoreLink">
      <h1 class="font-semibold">{{ translate('recent posted', 'ucfirst') }}</h1>
      <RouterLink :to="`/dashboard/company/${store.getters.getUserSlug}/jobs`" class="underline font-semibold text-oxford-blue">
        {{ translate('see more', 'ucfirst') }}
      </RouterLink>
    </div>
    <div
      class="col-span-1 md:col-span-4 py-3 bg-white rounded-md shadow-md overflow-hidden"
    >
      <DataTable :value="jobs" class="w-full">
        <Column :header="translate('title', 'ucfirst')" field="job_title"></Column>
        <Column field="number_of_applications" :header="translate('appliances', 'ucfirst')"></Column>
        <Column field="full_location" :header="translate('address', 'ucfirst')"> </Column>
        <Column field="job_created_at" :header="translate('date', 'ucfirst')"></Column>
        <Column :header="translate('status', 'ucfirst')">
          <template #body="slotProps">
            <Tag
              :value="slotProps.data.job_status_translation"
              :severity="getSeverity(slotProps.data.job_status)"
            />
          </template>
        </Column>
        <Column v-if="props.displayButtons" class="flex justify-between">
          <template #body="slotProps">
            <RouterLink
              v-if="[undefined, null, 0].includes(env('APP_BETA'))"
              :to="`/dashboard/${userType}/${slug}/jobs/${slotProps.data.job_id}`"
              v-tooltip.top="translate('preview job', 'ucfirst')" type="text" placeholder="Top"
              ><i class="pi pi-eye"></i></RouterLink
            >
            <RouterLink
              :to="`/dashboard/${userType}/${slug}/post-job/${slotProps.data.job_id}`"
              v-tooltip.top="translate('edit job', 'ucfirst')" type="text" placeholder="Top"
              ><i class="pi pi-pen-to-square"></i></RouterLink
            >
          </template>
        </Column>
        <template #footer> {{ translate('a total of', 'ucfirst') }} {{ jobs.length }} {{ translate('jobs') }}. </template>
      </DataTable>
    </div>
  </section>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import { useStore } from "vuex";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import { onMounted, reactive, ref } from "vue";
import requestHandler from "@/composables/requestHandler";
import { showToast } from "@/composables/toast";
import { useToast } from "primevue/usetoast";
import utils from "@/composables/utils";
import { translate } from "@/composables/translate";
import { env } from "@/composables/config";

const props = defineProps({
  displayButtons: {
    type: Boolean,
    required: false,
    default: false
  },
  limit: {
    type: String,
    required: false,
    default: ''
  },
  seeMoreLink: {
    type: Boolean,
    required: false,
    default: false
  }
});

const toast   = useToast();
const store   = useStore();
let slug      = store.getters.getUserSlug;
let userType  = store.getters.getLoggedAs
let isLoading = ref();
let jobs      = reactive([]);

const getSeverity = (jobStatus) => {
  switch (jobStatus) {
    case "published":
      return "success";
    case "validating":
      return "warning";
    case "hidden":
      return "secondary";
    case "draft":
      return "warning";
    default:
      return null;
  }
};

onMounted(function(){
  getCompanyJobs();
})

async function getCompanyJobs(){
  isLoading.value = true;
  let jobsData = [];
  let myCompanyJobs = store.getters.getOnDataChest('my_company_jobs');
  if(myCompanyJobs !== null && myCompanyJobs !== undefined && !utils.isEmpty(myCompanyJobs)){
    jobsData = myCompanyJobs;
    isLoading.value = false;
  }else{
    const response = await requestHandler.executeRequest('/api/company/jobs', {}, 'get');
    isLoading.value = false;
    if(!response.success){
      showToast(toast, response.message, 'error');
      return;
    }
    jobsData = response.data.data;
    store.dispatch('saveOnDataChest', {
      key: 'my_company_jobs',
      value: jobsData
    });
    for(let job of jobsData){
      store.dispatch('saveOnDataChest', {
        key: `save_job_${job.job_id}`,
        value: job
      });
    }
  }
  if(props.limit){
    let numericLimit = props.limit == '' ? 0 : parseInt(props.limit);
    let filteredJobs = [];
    for(let i = 0; i < numericLimit; i++){
      if(jobsData[i] == undefined)
        break;
      filteredJobs.push(jobsData[i]);
    }
    jobsData = filteredJobs;
  }
  jobs = jobsData;
}

</script>

<style scoped></style>
