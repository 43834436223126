<template>
  <div> 
    <LoadingDialog :visible="actionIsBeingExecuted" />

    <ConfirmationModal 
      :title="confirmationDialogInfo[actionToConfirm] != undefined ? confirmationDialogInfo[actionToConfirm].title : ''"
      :text="getTextOfActionToConfirm()"
      :visible="confirmationModalVisibility"
      @emitResponse="handleConfirmationModal"
    />

    <LoaderComponent 
      class="flex justify-center text-center justify-center mt-5 mb-5 py-5" 
      v-if="isLoadingJob" 
    />
    <div
      class="grid grid-cols-5 xl:grid-cols-3 gap-4 xl:gap-x-6 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8"
      v-if="!isLoadingJob"
    > 
      <div class="col-span-5 py-3 px-4 rounded bg-white border">
        <Tabs>
          <TabList class="w-full flex flex-wrap justify-between"> 
            <!-- tab-item px-2 cursor-pointer w-full border rounded p-2 mb-2 -->
            <Tab 
              :class="isScreenSmall() ? `tab-item px-2 cursor-pointer w-full border rounded p-2 mb-2 ${selectedTab != tab.id ? 'hover-tab-item' : ''}` : `tab-item px-2 py-2 cursor-pointer ${selectedTab != tab.id ? 'hover-tab-item' : ''}`" 
              v-for="tab in tabItems"  
              :key="tab.label" 
              :value="tab.route"
              @click.prevent="changeTab(tab.id)"
              :style="canAccessTab() ? '' : 'pointer-events:none;opacity:0.5;'"
              :disabled="canAccessTab()"
            >
              <a 
                v-ripple 
                :class="`flex items-center gap-2 text-inherit  ${selectedTab == tab.id ? 'selected-tab-item' : ''}`" 
              >
                <i :class="tab.icon" />
                <span>{{ tab.label }}</span>
              </a>
            </Tab>
          </TabList>
        </Tabs>
      </div>

      <SkillsTab v-if="selectedTab =='skills'" :jobId="jobId" />

      <LanguagesTab v-if="selectedTab =='languages'" :jobId="jobId" />

      <VisasTab v-if="selectedTab == 'visas'" :jobId="jobId" />

      <DrivingLicensesTab v-if="selectedTab == 'drivingLicenses'" :jobId="jobId" />

      <CertificationsTab v-if="selectedTab == 'certifications'" :jobId="jobId" />
      
      <!-- Seção dos campos formulário -->
      <div class="content-wrapper col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5" v-if="selectedTab == 'main'">
        <section
          class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron"
        >
          <h1 class="mb-6 text-lg font-semibold">{{ translate('job information', 'ucfirst') }}</h1>

          <form
            class="flex flex-col text-base font-medium"
          > 
            <div class="flex flex-wrap">
              <span class="w-full md:w-2/3 p-float-label mb-5 mt-3 md:pr-3">
                <InputText
                  v-model.trim="jobForm.job_title"
                  type="text"
                  id="job_title"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                  aria-describedby="text-error"
                  required
                />
                <label id="label-job_title" for="job_title">{{ translate('what is the title of the job?', 'ucfirst') }} *</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <Dropdown
                  v-model="jobForm.job_modality_id"
                  :options="comboData.data.job_modality_id"
                  :loadind="comboData.loading.job_modality_id"
                  optionLabel="name"
                  :placeholder="translate('select a modality', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_modality_id"
                />
                <label id="label-job_modality_id" for="job_modality_id">{{ translate('what is the job modality?', 'ucfirst') }} *</label>
              </span>
            </div>
            <div class="flex flex-wrap pt-3">
              <span class="w-full md:w-1/3 p-float-label mb-5 mt-3 md:pr-3 p-float-label">
                <Dropdown
                  v-model="jobForm.wage_currency"
                  :options="comboData.data.wage_currency"
                  :loadind="comboData.loading.wage_currency"
                  optionLabel="name"
                  :placeholder="translate('select a currency', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="wage_currency"
                />
                <label id="label-wage_currency" for="wage_currency">{{ translate('what is the wage currency?', 'ucfirst') }} *</label>
              </span>
              <span class="w-full md:w-1/3 p-float-label mb-5 mt-3 md:pr-3 p-float-label">
                <InputNumber
                  v-model.trim="jobForm.minimum_wage"
                  inputId="minimum_wage"
                  mode="currency"
                  :currency="getCurrencyByWageCurrency()"
                  :min="0"
                  :max="jobForm.max_wage"
                  :disabled="jobForm.wage_currency == null" 
                  inputClass="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold"
                />
                <label id="label-minimum_wage" for="minimum_wage">{{ translate('minimum wage', 'ucfirst') }} *</label>
              </span>
              <span class="w-full md:w-1/3 p-float-label mb-5 mt-3 md:pr-3 p-float-label">
                <InputNumber
                  v-model.trim="jobForm.max_wage"
                  mode="currency"
                  :currency="getCurrencyByWageCurrency()"
                  :disabled="jobForm.wage_currency == null"
                  inputClass="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold"
                  required
                  :min="jobForm.minimum_wage"
                  inputId="max_wage"
                />
                <label id="label-max_wage" for="max_wage">{{ translate('max wage', 'ucfirst') }} *</label>
              </span>
            </div>
            <div class="flex flex-wrap pt-3">
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <Dropdown
                  v-model="jobForm.job_language"
                  :options="comboData.data.languages"
                  :loadind="comboData.loading.languages"
                  optionLabel="name"
                  :placeholder="translate('select a language', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_language"
                />
                <label id="label-job_language" for="job_language">{{ translate('what is the language on the text of this job?', 'ucfirst') }} *</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <LoaderComponent v-if="comboData.loading.job_payment_type" />
                <Dropdown
                  v-if="!comboData.loading.job_payment_type"
                  v-model="jobForm.job_payment_type"
                  :options="comboData.data.job_payment_type"
                  :loading="comboData.loading.job_payment_type"
                  optionLabel="name"
                  :placeholder="translate('select a paying type', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_payment_type"
                />
                <label v-if="!comboData.loading.job_payment_type" id="label-job_payment_type" for="job_payment_type">{{ translate('job payment type', 'ucfirst') }}</label>
              </span>
            </div>
            <span class="col-span-3 border-t iron pt-3">{{ translate('contact', 'ucfirst') }}:</span>
            <div class="flex flex-wrap pt-3">
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <InputText
                  v-model.trim="jobForm.contact_name"
                  type="text"
                  id="contact_name"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                />
                <label id="label-contact_name" for="contact_name">{{ translate('contact name', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <InputText
                  v-model.trim="jobForm.contact_email"
                  type="text" 
                  id="contact_email"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                />
                <label id="label-contact_email" for="contact_email">{{ translate('contact email', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <InputText
                  v-model.trim="jobForm.contact_website"
                  type="text"
                  id="contact_website"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                />
                <label id="label-contact_website" for="contact_website">{{ translate('contact website', 'ucfirst') }}</label>
              </span>
            </div>
            <div class="flex flex-wrap pt-3">
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label" >
                  <LoaderComponent v-if="comboData.loading.ddi" />
                  <Dropdown 
                    v-if="!comboData.loading.ddi"
                    id="ddi"
                    v-model="jobForm.ddi" 
                    :options="comboData.data.ddi"
                    filter 
                    optionLabel="name" 
                    :inputClass="'text-oxford-blue font-semibold'"
                    :placeholder="translate('ddi', 'upper')" 
                    class="w-full rounded-full border border-iron text-oxford-blue"
                  >
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            <img 
                              :alt="slotProps.value.label" 
                              :src="getOnPublic(slotProps.value.flagSrc)" 
                              :class="`mr-2 flag flag-${slotProps.value.name}`" 
                              style="width: 18px" 
                            />
                            <div>{{ slotProps.value.ddi }} {{ slotProps.value.name }}</div>
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <img 
                              :alt="slotProps.option.label" 
                              :src="getOnPublic(slotProps.option.flagSrc)" 
                              :class="`mr-2 flag flag-${slotProps.option.code}`" 
                              style="width: 18px" 
                            />
                            <div>{{ slotProps.option.ddi }} {{ slotProps.option.name }}</div>
                        </div>
                    </template>
                </Dropdown>
                <label id="label-ddi" v-if="!comboData.loading.ddi" for="ddi">{{ translate('ddi', 'upper') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <InputText
                  v-model.trim="jobForm.contact_phone"
                  type="text"
                  id="contact_phone"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                />
                <label id="label-contact_phone" for="contact_phone">{{ translate('contact phone', 'ucfirst') }}</label>
              </span>
            </div>
            <span class="col-span-3 border-t iron pt-3">{{ translate('location', 'ucfirst') }}:</span>
            <div class="flex flex-wrap pt-3 justify-center">
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <InputText
                  v-model.trim="jobForm.job_city"
                  type="job_city"
                  id="city"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                />
                <label for="job_city">{{ translate('city', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <InputText
                  v-model.trim="jobForm.job_state"
                  type="text"
                  id="job_state"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue"
                />
                <label for="job_state">{{ translate('state', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                <Dropdown
                  v-model="jobForm.job_country"
                  :options="comboData.data.job_country"
                  :loadind="comboData.loading.job_country"
                  optionLabel="name"
                  :placeholder="translate('select a type', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_country"
                />
                <label id="label-job_country" for="job_country">{{ translate('country', 'ucfirst') }} *</label>
              </span>
            </div>
            <span class="col-span-3 border-t iron pt-3">{{ translate('details', 'ucfirst') }}:</span>
            <LoaderComponent class="col-span-3 mb-5" v-if="combosLoading" />
            <div class="flex flex-wrap pt-3" v-if="!combosLoading">
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label">
                  <Dropdown
                    v-model="jobForm.profession_for_job"
                    :options="comboData.data.profession_for_job"
                    :loading="comboData.loading.profession_for_job"
                    optionLabel="name"
                    :placeholder="translate('select a profession', 'ucfirst')"
                    class="w-full rounded-full border border-iron text-oxford-blue"
                    :inputClass="'text-oxford-blue font-semibold'"
                    required
                    filter
                    id="profession_for_job"
                  />
                  <label id="label-profession_for_job" for="profession_for_job">{{ translate('profession', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label" v-if="!combosLoading" >
                <Dropdown
                  v-model="jobForm.job_contract"
                  :options="comboData.data.job_contract"
                  :loadind="comboData.loading.job_contract"
                  optionLabel="name"
                  :placeholder="translate('select a job contract', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_contract"
                />
                <label id="label-job_contract" for="job_contract">{{ translate('job contract', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label" v-if="!combosLoading" >
                <Dropdown
                  v-model="jobForm.job_period"
                  :options="comboData.data.job_period"
                  :loadind="comboData.loading.job_period"
                  optionLabel="name"
                  :placeholder="translate('select a job period', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_period"
                />
                <label id="label-job_period" for="job_period">{{ translate('job period', 'ucfirst') }}</label>
              </span>
            </div>
            <div class="flex flex-wrap pt-3" v-if="!combosLoading">
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label" v-if="!combosLoading" >
                <Dropdown
                  v-model="jobForm.working_visa"
                  :options="comboData.data.working_visa"
                  :loadind="comboData.loading.working_visa"
                  optionLabel="name"
                  :placeholder="translate('select a working visa', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="working_visa"
                />
                <label id="label-working_visa" for="working_visa">{{ translate('visa option', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label" v-if="!combosLoading" >
                <Dropdown
                  v-model="jobForm.job_seniority"
                  :options="comboData.data.job_seniority"
                  :loading="comboData.loading.job_seniority"
                  optionLabel="name"
                  :placeholder="translate('select a seniority', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="job_seniority"
                />
                <label id="label-job_seniority" for="job_seniority">{{ translate('job seniority', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/3 mb-5 mt-3 md:pr-3 p-float-label" v-if="!combosLoading" >
                <Dropdown
                  v-model="jobForm.experience_in_months"
                  :options="comboData.data.experiences"
                  :loading="comboData.loading.experiences"
                  optionLabel="name"
                  :placeholder="translate('select an option', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="experience_in_months"
                />
                <label id="label-experience_in_months" for="experience_in_months">{{ translate('experience in months', 'ucfirst') }}</label>
              </span>
            </div>
            <span class="col-span-3 border-t iron pt-3 pb-3">{{ translate('additional informations', 'ucfirst') }}:</span>
            <span class="col-span-1 md:col-span-3 p-float-label mt-3">
              <TextArea
                v-model.trim="jobForm.job_description"
                id="job_description"
                class="w-full px-3 py-2 rounded-xl border border-iron"
                required
              />
              <label id="label-job_description" for="job_description">{{ translate('job description', 'ucfirst') }}</label>
              <div class="w-full text-right">
                <small>{{ jobForm.job_description != null ? jobForm.job_description.length : 0 }} {{ translate('characters') }}</small>
              </div>
            </span>
            <span class="col-span-1 md:col-span-3 p-float-label">
              <TextArea
                v-model.trim="jobForm.job_experience_description"
                id="job_experience_description"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
              <label for="job_experience_description">{{ translate('activities', 'ucfirst') }}</label>
              <div class="w-full text-right">
                <small>{{ jobForm.job_experience_description != null ? jobForm.job_experience_description.length : 0 }} {{ translate('characters') }}</small>
              </div>
            </span>
            <span class="col-span-1 md:col-span-3 p-float-label">
              <TextArea
                v-model.trim="jobForm.job_requirements"
                id="job_requirements"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
              <label for="job_requirements">{{ translate('requirements', 'ucfirst') }}</label>
              <div class="w-full text-right">
                <small>{{ jobForm.job_requirements != null ? jobForm.job_requirements.length : 0 }} {{ translate('characters') }}</small>
              </div>
            </span>
            <span class="col-span-1 md:col-span-3 w-full p-float-label">
              <TextArea
                v-model.trim="jobForm.job_offer"
                id="job_offer"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
              <label for="job_offer">{{ translate('benefits', 'ucfirst') }}</label>
              <div class="w-full text-right">
                <small>{{ jobForm.job_offer != null ? jobForm.job_offer.length : 0 }} {{ translate('characters') }}</small>
              </div>
            </span>
          </form>
        </section>
      </div>

      <!-- Barra lateral direita -->
      <aside
        v-if="selectedTab == 'main'"
        class="col-span-5 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
      >
        <Panel
          :collapsed="false"
          toggleable
          class="shadow-md border border-iron pannel-wrapper"
        >
          <div class="m-0 relative">
            <div class="flex flex-wrap gap-y-3 justify-between items-center mb-4">
              <Button
                type="text"
                :label="translate('save draft', 'ucfirst')"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent w-full md:w-1/3"
                :loading="isSavingJob"
                v-if="jobStatus != 'hidden'"
                @click.prevent="openConfirmationModal('saveJob')"
              />
              <Button
                type="text"
                label="Preview"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
                v-if="![null,undefined,''].includes(jobId) && [undefined, null, 0].includes(env('APP_BETA'))"
                @click.prevent="previewJob()"
              />
            </div>
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex items-center gap-2">
                <i class="pi pi-folder"></i>
                <p>{{ translate('status', 'ucfirst') }}:</p>
                <strong>{{ selectedStatusTranslation }}</strong>
              </li>
              <li class="flex items-center gap-2">
                <i :class="`pi ${jobStatus == 'published' ? 'pi-eye' : 'pi-eye-slash'}`"></i>
                <p>{{ translate('visibility', 'ucfirst') }}:</p>
                <strong>{{ jobStatus == 'published' ? translate('public', 'ucfirst') : translate('hidden', 'ucfirst') }}</strong>
              </li>
              <li class="flex items-center gap-2">
                <i class="pi pi-calendar"></i>
                <p>{{ translate('published', 'ucfirst') }}:</p>
                <strong>{{ getPublishedDate() }}</strong>
              </li>
            </ul>
          </div>
          <template #footer>
            <div 
              class="flex justify-between items-center pt-3 border-t border-iron flex-wrap" 
              v-if="jobId != null"
              :style="isScreenSmall() ? {'flex-direction': 'column-reverse'} : ''"
            >
              <p 
                class="text-red-800 cursor-pointer underline text-center md:text-left"  
                @click="openConfirmationModal('sendToTrash')"
                v-if="jobStatus != 'hidden'"
              >
                {{ translate('send to trash', 'ucfirst') }}
              </p>
              <p 
                class="text-red-800 cursor-pointer underline" 
                @click="openConfirmationModal('untrashJob')"
                v-if="jobStatus == 'hidden'"
              >
                {{ translate('remove job from trash', 'ucfirst') }}
              </p>
              <Button
                v-if="canPublish()"
                @click="openConfirmationModal('publish')"
                :label="translate('publish', 'ucfirst')"
                class="py-1 px-3 text-white rounded-md bg-sea-serpent w-full md:w-1/3"
                :style="isScreenSmall() ? {'margin-bottom': '2em'} : ''" 
              />
            </div>
          </template>
        </Panel>
      </aside>
      <!-- Fim barra lateral direita -->
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, watch, onBeforeMount } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Panel from "primevue/panel";
import TextArea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import { translate } from "@/composables/translate";
import { useRoute } from 'vue-router';
import { showToast } from "@/composables/toast";
import jobData from "@/composables/jobData";
import requestHandler from "@/composables/requestHandler";
import store from "@/store";
import { ucfirst } from "@/composables/textUtils";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import utils from "@/composables/utils";
import ConfirmationModal from "@/components/Utils/ConfirmationModal";
import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
import { env } from "@/composables/config";
import SkillsTab from "@/components/Dashboard/Company/SaveJob/SkillsTab";
import LanguagesTab from "@/components/Dashboard/Company/SaveJob/LanguagesTab";
import DrivingLicensesTab from "@/components/Dashboard/Company/SaveJob/DrivingLicensesTab";
import VisasTab from "@/components/Dashboard/Company/SaveJob/VisasTab.vue";
import CertificationsTab from "@/components/Dashboard/Company/SaveJob/CertificationsTab.vue";
import { onUnmounted } from "vue";
import { getOnPublic } from "@/composables/imageChecker";

// ############ Declaração de variáveis ############

let toast = useToast();
let route = useRoute();

let actionIsBeingExecuted = ref(false);
let isSavingJob = ref(false);
let isLoadingJob = ref(false);
let jobId = ref(null);
let jobStatus = ref('draft');
let job = ref(null);
let confirmationModalVisibility = ref(false);

let avaliableJobStatus = {
  draft: translate('draft', 'ucfirst'),
  published: translate('published', 'ucfirst'),
  validating: translate('validating', 'ucfirst'),
  hidden: translate('hidden', 'ucfirst')
};
let selectedStatusTranslation = ref(avaliableJobStatus.draft);

let jobForm = reactive({
  job_id: null,
  job_title: null,
  job_modality_id: null,
  job_description: null,
  wage_currency: null,
  minimum_wage: 1,
  max_wage: 2,
  job_country: null,
  job_requirements: null,
  contact_email: null,
  contact_name: null,
  contact_phone: null,
  contact_website: null,
  job_language: null,
  job_city: null,
  job_state: null,
  job_offer: null,
  job_experience_description: null,
  profession_for_job: null,
  job_period: null,
  job_contract: null,
  working_visa: null,
  job_seniority: null,
  job_payment_type: null,
  experience_in_months: null,
  ddi: null
});

let selectedTab = ref('main');
const tabItems = ref([
  { id: 'main', label: translate('job information', 'ucfirst'), icon: 'pi pi-home'},
  { id: 'skills', label: translate('skills', 'ucfirst'), icon: 'pi pi-hammer', status: 'published'},
  { id: 'languages', label: translate('languages', 'ucfirst'), icon: 'pi pi-language', status: 'published'},
  { id: 'visas', label: translate('visas', 'ucfirst'), icon: 'pi pi-receipt', status: 'published'},
  { id: 'drivingLicenses', label: translate('driving license', 'ucfirst'), icon: 'pi pi-id-card', status: 'published'},
  { id: 'certifications', label: translate('certifications', 'ucfirst'), icon: 'pi pi-graduation-cap', status: 'published'}
]);

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############

const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const breakPoint = 768;
onMounted(function(){
  jobId.value = route.params != undefined && route.params.job_id != undefined ? route.params.job_id : null;
  if(![null,undefined,''].includes(jobId.value)){
    jobForm.job_id = jobId.value;
    feedFieldsWithJob();
  }else{
    jobId.value = null;
    job.value = null;
    jobStatus.value = 'draft';
    isLoadingJob.value = false;
  }
  handleHtmlSize();
  handlePanel();
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})

watch(() => route.fullPath, () => {
  selectedTab.value = 'main';
});
watch(() => isLoadingJob, () => {
  handleHtmlSize();
});
watch(() => jobStatus, (newStatus) => {
  selectedStatusTranslation.value = avaliableJobStatus[newStatus];
});
watch(() => selectedTab, () => {
  handlePanel(); 
});

// Watching size of text areas
let maxSizeMessage = translate('max size of 1500 characters reached', 'ucfirst');
watch(() => jobForm.job_description, (newJobDescription) => {
  let length = newJobDescription != null ? newJobDescription.length : 0;
  if(length > 1500){
    highlightInput('job_description');
    showToast(toast, maxSizeMessage, 'error');
  }else{
    highlightInput('job_description', false);
  }
});
watch(() => jobForm.job_experience_description, (newJobExperienceDescription) => {
  let length = newJobExperienceDescription != null ? newJobExperienceDescription.length : 0;
  if(length > 1500){
    highlightInput('job_experience_description');
    showToast(toast, maxSizeMessage, 'error');
  }else{
    highlightInput('job_experience_description', false);
  }
});
watch(() => jobForm.job_requirements, (newJobRequirements) => {
  let length = newJobRequirements != null ? newJobRequirements.length : 0;
  if(length > 1500){
    highlightInput('job_requirements');
    showToast(toast, maxSizeMessage, 'error');
  }else{
    highlightInput('job_requirements', false);
  }
});
watch(() => jobForm.job_offer, (newJobOffer) => {
  let length = newJobOffer != null ? newJobOffer.length : 0;
  if(length > 1500){
    highlightInput('job_offer');
    showToast(toast, maxSizeMessage, 'error');
  }else{
    highlightInput('job_offer', false);
  }
});

watch(() => store.getters.hasRouteChanged, (newRoute) => {
  if(newRoute == `/dashboard/company/${store.getters.getUserSlug}/post-job`){
    jobId.value = null;
    job.value = null;
    jobStatus.value = 'draft';
    isLoadingJob.value = false;
    handleHtmlSize();
    handlePanel();
    for(let key in jobForm){
      let value = null;
      if(key == 'minimum_wage')
        value = 1;
      if(key == 'max_wage')
        value = 2;
      jobForm[key] = value;
    }
  }
});

onBeforeMount(function(){
  generateExperiencesCombo();
  getComboData();
}) 

async function feedFieldsWithJob(){
  isLoadingJob.value = true;
  let myJob = store.getters.getOnDataChest(`save_job_${jobId.value}`);
  if(myJob !== null && myJob !== undefined && !utils.isEmpty(myJob)){
    job.value = myJob;
  }else{
    const response = await jobData.searchForMyCompanyJob(jobId.value);
    if(!response.success || response.data.data.length < 1){
      store.dispatch('saveSessionMessage', {message: translate('job not found'), messageType: 'error'});
      await requestHandler.redirect(`/dashboard/company/${store.getters.getUserSlug}`);
      return;
    }
    job.value = response.data.data[0];
    store.dispatch('saveOnDataChest', {
      key: `save_job_${jobId.value}`,
      value: job.value
    });
  }
  jobForm.job_title = job.value.job_title;
  jobForm.job_description = job.value.job_description;
  jobForm.job_modality_id = {
    code: job.value.job_modality_id,
    name: ucfirst(job.value.job_modality)
  }
  jobForm.wage_currency = {
    code: job.value.wage_currency_id,
    name: job.value.wage_currency_name
  } 
  jobForm.minimum_wage = job.value.minimum_wage;
  jobForm.max_wage = job.value.max_wage;
  jobForm.job_country = {
    code: job.value.job_country_id,
    name: job.value.job_country
  }
  jobForm.job_requirements = job.value.job_requirements;
  jobForm.contact_email = job.value.contact_email;
  jobForm.contact_name = job.value.contact_name;
  jobForm.contact_phone = job.value.contact_phone;
  jobForm.job_language = {
    code: job.value.job_language,
    name: ucfirst(job.value.job_language_name)
  }
  jobForm.job_city = job.value.job_city;
  jobForm.job_state = job.value.job_state;
  jobForm.job_offer = job.value.job_offer;
  jobForm.job_experience_description = job.value.job_experience_description;
  if(job.value.profession_for_job_id){
    jobForm.profession_for_job = {
      code: job.value.profession_for_job_id,
      name: ucfirst(job.value.profession_for_job)
    }
  }else{
    jobForm.profession_for_job = null;
  }
  if(job.value.job_contract_id){
    jobForm.job_contract = {
      code: job.value.job_contract_id,
      name: ucfirst(job.value.job_contract)
    }
  }else{
    jobForm.job_contract = null;
  }
  if(job.value.job_period_id){
    jobForm.job_period = {
      code: job.value.job_period_id,
      name: ucfirst(job.value.job_period)
    }
  }else{
    jobForm.job_period = null
  }
  if(job.value.working_visa_id){
    jobForm.working_visa = {
      code: job.value.working_visa_id,
      name: ucfirst(job.value.working_visa)
    }
  }else{
    jobForm.working_visa = null;
  }
  if(job.value.job_seniority_id){
    jobForm.job_seniority = {
      code: job.value.job_seniority_id,
      name: ucfirst(job.value.job_seniority)
    }
  }else{
    jobForm.working_visa = null;
  }
  if(job.value.experience_in_months){
    let code = job.value.experience_in_months / 12;
    let name = '';
    if(comboData.data.experiences[code] != undefined)
      name = comboData.data.experiences[code].name
    jobForm.experience_in_months = {
      code: code,
      name: name
    }
  }else{
    jobForm.experience_in_months = null;
  }
  jobForm.ddi = null;
  jobStatus.value = job.value.job_status != undefined ? job.value.job_status : 'draft';
  selectedStatusTranslation.value = avaliableJobStatus[jobStatus.value];
  isLoadingJob.value = false;
}

let comboData = reactive({
  combos: [
    'job_modality_id','wage_currency','job_country', 'ddi', 'languages','profession_for_job','job_period',
    'job_contract','working_visa','job_seniority','job_payment_type', 
  ],
  data: {
    job_modality_id: [],
    wage_currency: [],
    job_country: [],
    languages: [],
    profession_for_job: [],
    job_period: [],
    job_contract: [],
    working_visa: [],
    experiences: [],
    job_seniority: [],
    job_payment_type: [],
    ddi: []
  },
  raw: {
    wage_currency: []
  },
  loading: {
    job_modality_id: true,
    wage_currency: true,
    job_country: true,
    languages: true,
    profession_for_job: true,
    job_period: true,
    job_contract: true,
    working_visa: true,
    experiences: true,
    job_seniority: true,
    job_payment_type: true,
    ddi: true
  }
})
let combosLoading = ref(true);
async function getComboData(){
  combosLoading.value = true;
  for(let key of comboData.combos){
    comboData.loading[key] = true;
    let withRaw = false;
    if(['wage_currency'].includes(key))
      withRaw = true;
    const result = await jobData.getJobComboData(key, true);
    if(withRaw){
      comboData.data[key] = result.processed;
      comboData.raw[key]  = result.raw;
    }else{
      comboData.data[key] = result;
    }
    comboData.loading[key] = false;

    if(key == 'ddi' && jobForm.job_id){
      for(let country of comboData.data[key]){
        if(country['ddi'] == job.value.ddi){
          jobForm.ddi = country;
          break;
        }
      }
    }
  }
  combosLoading.value = false;
}

function changeTab(tabId = 'main'){
  selectedTab.value = tabId;
  if(tabId == 'main'){
    handlePanel();
  }
}

function canAccessTab(){
  if([null, undefined, ''].includes(jobId.value))
    return false;
  return true;
}

function previewJob(){
  console.log('preview')
}

function canPublish(){
  if(
    !['',null,undefined].includes(job) && 
    !['',null,undefined].includes(job.value) && 
    !['',null,undefined].includes(job.value.job_id) && 
    !['published','hidden'].includes(jobStatus.value)
  )
    return true;
  return false;
}

function isPublished(){
  if(!['',null,undefined].includes(job) && !['',null,undefined].includes(job.value))
    return true;
  return false;
}

function getPublishedDate(){
  if(!isPublished())
    return translate('not published', 'ucfirst');
  return job.value.posted_at;
}

function getCurrencyByWageCurrency(){
  let commonCurrencyId = jobForm.wage_currency != null ? jobForm.wage_currency.code : false;
  if(comboData.raw.wage_currency.length > 0){ 
    for(let currency of comboData.raw.wage_currency){
      if(currency.object_id == commonCurrencyId)
        return currency.currency;
    }
  }
  return 'EUR';
}

let requiredFormData = {
  job_title: translate('job title'),
  job_modality_id: translate('job modality'),
  wage_currency: translate('wage currency'),
  minimum_wage: translate('minimum wage'),
  max_wage: translate('max wage'),
  job_country: translate('country'),
  job_language: translate('job language')
}
let requiredAtLeastOne = {
  contact_email: translate('contact email'),
  contact_phone: translate('contact phone'),
  contact_website: translate('contact website')
}
async function saveJob(){
  for(let key of Object.keys(jobForm)){
    if(requiredFormData[key] != undefined){
      let value = jobForm[key];
      if([null,undefined,''].includes(value)){
        highlightInput(key);
        showToast(toast, `${translate('required value missing')}: ${requiredFormData[key]}`, 'error');
        return;
      }else{
        highlightInput(key, false)
      }
    }
  }
  let hasOne = false;
  for(let key in requiredAtLeastOne){
    if(![null,undefined,''].includes(jobForm[key])){
      hasOne = true;
      break;
    }
  }
  if(!hasOne){
    showToast(toast, translate('you must inform a contact information such as: contact email, contact phone or contact website', 'ucfirst'), 'error');
    return;
  }
  if(jobForm.contact_phone && !jobForm.ddi){
    highlightInput('ddi');
    showToast(toast, translate('please enter a ddi for the contact phone', 'ucfirst'), 'error');
    return;
  }
  highlightInput('ddi', false);
  if(!jobForm.contact_phone && jobForm.ddi){
    highlightInput('contact_phone');
    showToast(toast, translate('please enter a phone number', 'ucfirst'), 'error');
    return;
  }
  highlightInput('contact_phone', false);
  let refactorForm = utils.copyVariable(jobForm);
  refactorForm.ddi = refactorForm.ddi ? refactorForm.ddi.ddi : null;
  for(let key of comboData.combos){
    let formKey = key == 'languages' ? 'job_language' : key;
    if(refactorForm[formKey] == null)
      continue;
    if(requiredFormData[formKey] !== undefined && ['',undefined,null].includes(refactorForm[formKey])){
      highlightInput(formKey);
    }else{
      highlightInput(formKey, false);
      refactorForm[formKey] = refactorForm[formKey]['code'];
    }
  }
  if(!['',undefined,null].includes(refactorForm['experience_in_months'])){
    refactorForm['experience_in_months'] = refactorForm['experience_in_months']['code'] * 12;
  }
  isSavingJob.value = true;
  const response = await jobData.saveJob(refactorForm);
  isSavingJob.value = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  if([undefined,null,''].includes(refactorForm.job_id)){
    job.value = response.data.data;
    jobId.value = response.data.data.job_id;
    jobStatus.value = response.data.data.job_status;
    jobForm.job_id = response.data.data.job_id;
    store.dispatch('saveOnDataChest', {
      key: `save_job_${jobId.value}`,
      value: response.data.data
    });
    await jobData.updateMyCompanyJobs(jobId.value, job.value);
    showToast(toast, response.message);
    await requestHandler.redirect(`/dashboard/company/${store.getters.getUserSlug}/post-job/${response.data.data.job_id}`);
    return;
  }else{
    showToast(toast, response.message);
    store.dispatch('saveOnDataChest', {
      key: `save_job_${jobId.value}`,
      value: response.data.data
    });
    job.value = response.data.data;
    jobStatus.value = job.value.job_status != undefined ? job.value.job_status : 'draft';
    await jobData.updateMyCompanyJobs(jobId.value, job.value);
  }
}

function generateExperiencesCombo(){
  comboData.loading.experiences = true;
  let options   = [];
  let yearsFrom = 0;
  let yearsTo   = 2;
  for(let i = 0; i < 35; i++){
    let translation = `${translate('from')} ${yearsFrom} ${translate('to')} ${yearsTo} ${translate('years of experience')}`;
    if(i == 0){
      translation = translate('no experience');
    }
    options.push({code: yearsFrom, name: ucfirst(translation)});
    yearsFrom++;
    yearsTo++;
  }
  options.push({code: yearsFrom, name: translate('more than 36 years of experience', 'ucfirst')});
  comboData.data.experiences = options;
  comboData.loading.experiences = false;
}

async function sendJobToTrash(){
  const response = await jobData.trashJob(jobId.value);
  if(!response.success){
    showToast(toast, response.message, 'error')
    return;
  }
  showToast(toast, response.message);
  store.dispatch('saveOnDataChest', {
    key: `save_job_${jobId.value}`,
    value: response.data.data
  });
  job.value = response.data.data;
  jobStatus.value = response.data.data.job_status;
  await jobData.updateMyCompanyJobs(jobId.value, job.value);
}

async function untrashJob(){
  const response = await jobData.untrashJob(jobId.value);
  if(!response.success){
    showToast(toast, response.message, 'error')
    return;
  }
  showToast(toast, response.message);
  store.dispatch('saveOnDataChest', {
    key: `save_job_${jobId.value}`,
    value: response.data.data
  });
  job.value = response.data.data;
  jobStatus.value = response.data.data.job_status;
  await jobData.updateMyCompanyJobs(jobId.value, job.value);
}

function highlightInput(id, highlight = true){
  let el = document.querySelector(`#label-${id}`);
  if(![undefined, null].includes(el)){
    if(highlight){
      el.style.color = 'red';
      el.click();
    }else{
      el.style.color = '';
    }
  }
}

async function postJob() {
  const response = await jobData.postJob(jobId.value);
  if(!response.success){
    showToast(toast, response.message, 'error')
    return;
  }
  showToast(toast, response.message);
  store.dispatch('saveOnDataChest', {
    key: `save_job_${jobId.value}`,
    value: response.data.data
  });
  job.value = response.data.data;
  jobStatus.value = response.data.data.job_status;
  await jobData.updateMyCompanyJobs(jobId.value, job.value);
}

function handleHtmlSize(){
  let idsToResize = ['minimum_wage','max_wage'];
  for(let id of idsToResize){
    let el = document.querySelector(`#${id}`);
    if(![undefined,null,''].includes(el))
      el.parentElement.className += ' w-full';
  }
}

let confirmationDialogInfo = reactive({
  publish: {
    title: translate('are you sure you want to publish this job?', 'ucfirst'),
    text:  translate('if yes, this job will be sent to our validation trials before being fully released to professionals', 'ucfirst')
  },
  sendToTrash: {
    title: translate('are you sure?', 'ucfirst'),
    text:  translate('if you confirm, your job will not be displayed for professionals anymore and in case you wish to reactivate this job, it will pass by the validation trials', 'ucfirst')
  },
  untrashJob: {
    title: translate('are you sure?', 'ucfirst'),
    text:  translate('if you confirm, your job will will be submitted to our validation trials before published', 'ucfirst')
  },
  saveJob: {
    title: translate('do you want to proceed?', 'ucfirst'),
    text:  '',
  }
});
      
let actionToConfirm = ref('');
async function handleConfirmationModal(confirmation){
  if(confirmation.confirmation){
    confirmationModalVisibility.value = false;
    actionIsBeingExecuted.value = true;
    switch(actionToConfirm.value){
      case 'publish':
        await postJob();
      break;
      case 'saveJob':
        await saveJob();
      break;
      case 'sendToTrash':
        await sendJobToTrash();
      break;
      case 'untrashJob':
        await untrashJob();
      break;
    }
    actionIsBeingExecuted.value = false;
  }
  actionToConfirm.value = '';
  confirmationModalVisibility.value = false;
  selectedStatusTranslation.value = avaliableJobStatus[jobStatus.value];
}
function openConfirmationModal(action = ''){
  actionToConfirm.value = action;
  confirmationModalVisibility.value = true;
}
function getTextOfActionToConfirm(){
  if(confirmationDialogInfo[actionToConfirm.value] == undefined)
    return '';
  if(actionToConfirm.value != 'saveJob')
    return confirmationDialogInfo[actionToConfirm.value].text;
  return [null, undefined, ''].includes(jobId.value) 
    ? translate('if yes a new job will be created, still not avaliable to professionals until you publish it afterwards and it passes the validation trials', 'ucfirst') 
    : translate('if yes your job will be updated, this will make your job unavaliable to professionals and will have to pass the validation trials when published', 'ucfirst');
}

function handlePanel(){
  let panelEl = document.querySelector('.pannel-wrapper');
  if(panelEl != null){
    let headerEl = panelEl.querySelector('.p-panel-header');
    if(headerEl != null && !headerEl.classList.contains('flex')){
      document.querySelector('.pannel-wrapper').children[0].classList.add('flex');
      document.querySelector('.pannel-wrapper').children[0].classList.add('justify-end');
    }
  }
}

function handleResize(){
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}
function isScreenSmall(){
  return windowWidth.value > breakPoint ? false : true;
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
</script>

<style scoped>
  .selected-tab-item{
    font-weight: 600;
    cursor: default;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(83 195 196 / var(--tw-bg-opacity));
    border-radius: 0.25rem;
  }
  .hover-tab-item:hover{
    border-bottom: solid;
  }
</style>
