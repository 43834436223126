import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const commonCurrencies = {
    async listCommonCurrencies() {
        try {
            let commonCurrencyCombo = store.getters.getOnComboChest('common_currency');
            if(commonCurrencyCombo !== null && commonCurrencyCombo !== undefined && !utils.isEmpty(commonCurrencyCombo))
                return commonCurrencyCombo;
            let currenciesFileData = requestHandler.fetchOnPublicData('currencies.json');
            if(currenciesFileData){
                store.dispatch('saveOnComboChest', {
                    key: 'common_currency',
                    value: currenciesFileData
                });
                return currenciesFileData
            }
            const result = await requestHandler.executeRequest('api/common_currency/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'common_currency',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default commonCurrencies;